import { Action } from '@ngrx/store';

export class OrganizationTreeTableLoading implements Action {
  readonly type = Type.ORGANIZATION_TREE_TABLE_LOADING;
  constructor() { }
}

export class OrganizationTreeTableLoadingComplete implements Action {
  readonly type = Type.ORGANIZATION_TREE_TABLE_LOADING_COMPLETE;
  constructor() { }
}

export class UserTableLoading implements Action {
    readonly type = Type.USER_TABLE_LOADING;
    constructor() { }
}

export class UserTableLoadingComplete implements Action {
    readonly type = Type.USER_TABLE_LOADING_COMPLETE;
    constructor() { }
}

export class DeletedGroupsTableLoading implements Action {
  readonly type = Type.DELETED_GROUPS_TABLE_LOADING;
  constructor() { }
}

export class DeletedGroupsTableLoadingComplete implements Action {
  readonly type = Type.DELETED_GROUPS_TABLE_LOADING_COMPLETE;
  constructor() { }
}

export class SetDeletedGroupsCount implements Action {
  readonly type = Type.SET_DELETED_GROUPS_COUNT;
  constructor(public payload: number) { }
}

export class OrphanGroupsTableLoading implements Action {
  readonly type = Type.ORPHAN_GROUPS_TABLE_LOADING;
  constructor() { }
}

export class OrphanGroupsTableLoadingComplete implements Action {
  readonly type = Type.ORPHAN_GROUPS_TABLE_LOADING_COMPLETE;
  constructor() { }
}

export class SetOrphanGroupsCount implements Action {
  readonly type = Type.SET_ORPHAN_GROUPS_COUNT;
  constructor(public payload: number) { }
}

export enum Type {
    ORGANIZATION_TREE_TABLE_LOADING = '[ORGANIZATION] ORGANIZATION_TREE_TABLE_LOADING',
    ORGANIZATION_TREE_TABLE_LOADING_COMPLETE = '[ORGANIZATION] ORGANIZATION_TREE_TABLE_LOADING_COMPLETE',
    USER_TABLE_LOADING = '[ORGANIZATION] USER_TABLE_LOADING',
    USER_TABLE_LOADING_COMPLETE = '[ORGANIZATION] USER_TABLE_LOADING_COMPLETE',
    DELETED_GROUPS_TABLE_LOADING = '[ORGANIZATION] DELETED_GROUPS_TABLE_LOADING',
    DELETED_GROUPS_TABLE_LOADING_COMPLETE = '[ORGANIZATION] DELETED_GROUPS_TABLE_LOADING_COMPLETE',
    SET_DELETED_GROUPS_COUNT = '[ORGANIZATION] SET_DELETED_GROUPS_COUNT',
    SET_ORPHAN_GROUPS_COUNT = '[ORGANIZATION] SET_ORPHAN_GROUPS_COUNT',
    ORPHAN_GROUPS_TABLE_LOADING = '[ORGANIZATION] ORPHAN_GROUPS_TABLE_LOADING',
    ORPHAN_GROUPS_TABLE_LOADING_COMPLETE = '[ORGANIZATION] ORPHAN_GROUPS_TABLE_LOADING_COMPLETE',
}

export type Actions = OrganizationTreeTableLoading
  | OrganizationTreeTableLoadingComplete
  | UserTableLoading
  | UserTableLoadingComplete
  | DeletedGroupsTableLoading
  | DeletedGroupsTableLoadingComplete
  | SetDeletedGroupsCount
  | OrphanGroupsTableLoading
  | OrphanGroupsTableLoadingComplete
  | SetOrphanGroupsCount
